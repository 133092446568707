const PRODUCT_LISTING_TYPE = 'product-listing';

export const getListingSlot = (page: any) => {
  let listingSlot = null as null | any;

  page?.sections?.some((section: any) =>
    section?.blocks?.some((block: any) =>
      block?.slots?.some((slot: any) => {
        const isListing =
          slot?.data?.listing && slot?.type === PRODUCT_LISTING_TYPE;
        if (isListing) {
          listingSlot = slot;
        }

        return isListing;
      })
    )
  );

  return listingSlot;
};
