import { computed } from 'vue';
import { useListing, ListingType } from '@shopware-pwa/composables-next';
import {
  ProductListingResult,
  ShopwareSearchParams,
} from '@shopware-pwa/types';
import { getSortingData } from '@/helpers/listing/getSortingData';

// proxy for cms useListing composable
// because of not actual returned data that was set up in admin panel,
// sometimes it returns some hardcoded default state for sortings
// possible also for getAvailableFilters property

const initialSortingOrders = reactive([]);
const initialSortingValue = ref('');

const useListingProxy = (
  parameterList:
    | {
        listingType: ListingType;
        defaultSearchCriteria?: Partial<ShopwareSearchParams>;
      }
    | undefined,
  page?: any
) => {
  const defaultCmsListing = useListing(parameterList);

  if (!initialSortingOrders.length && page) {
    const { sortingOrderList, defaultSortingValue } = getSortingData(
      defaultCmsListing,
      page
    );

    Object.assign(initialSortingOrders, sortingOrderList);

    initialSortingValue.value = defaultSortingValue;
  }

  return {
    ...defaultCmsListing,
    getSortingOrders: computed(() => initialSortingOrders),
    initialSortingValue: computed(() => initialSortingValue.value),
    setInitialListing: async (data: ProductListingResult) => {
      await defaultCmsListing.setInitialListing(data);
      // below example of request with addition attribute to get products quantity for each filter
      // according to this comment example: https://sowhat.atlassian.net/browse/LSWS-478?focusedCommentId=110700
      // await defaultCmsListing.search({
      //   query: data.currentFilters.search || '',
      //   'with-products-count': true
      // } as Partial<ShopwareSearchParams> & { 'with-products-count'?: boolean } );

      // CAUTION: return code below if filters will not works, because setInitialListing may be not set initial filters
      // and in console will be error "can't set filters to null"
      // if (initialSortingValue.value) {
      //   defaultCmsListing.changeCurrentSortingOrder(initialSortingValue.value);
      // }
    },
  };
};

export { useListingProxy as useListing };
