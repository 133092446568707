import { getListingSlot } from '@/helpers/listing/getListingSlot';
import { useI18n } from 'vue-i18n';

export const getSortingData = (defaultCmsListing: any, page: any) => {
  const { t } = useI18n();

  const listingSlot = getListingSlot(page);

  const listingMap: Record<string, number> = listingSlot
    ? listingSlot?.data?.listing?.availableSortings
    : {};
  // const listingMap: Record<string, number> = listingSlot
  //   ? listingSlot?.config?.availableSortings?.value
  //   : {};
  const listingMapEntries: [string, any][] = Object.entries(listingMap || {});

  let sortingOrderList = [];

  if (listingMapEntries.length) {
    sortingOrderList = listingMapEntries
      .sort(
        ([firstValue, firstItemPriority], [secondValue, secondItemPriority]) =>
          secondItemPriority - firstItemPriority
      )
      .map(([key, item]) => ({
        name: item.translated.label,
        id: item.key,
      }));
  } else if ('length' in defaultCmsListing.getSortingOrders.value) {
    sortingOrderList = defaultCmsListing.getSortingOrders.value.map(
      (sortingOrder: any) => {
        return {
          name: t(`types.listing.sorting.${sortingOrder.key}`),
          id: sortingOrder.key,
        };
      }
    );
  }

  return {
    sortingOrderList,
    defaultSortingValue: listingSlot?.config?.defaultSorting?.value || '',
  };
};
